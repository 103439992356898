import React from 'react'
import { graphql } from 'gatsby'
// import Img from 'gatsby-image'
import Pagina from '../components/Pagina'
import './styles/lw-volt.scss'
import BackgroundImg from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Component
import FloatSelo from '../components/FloatingSelo/selo'

// Imagens
import handFlower from '../images/sustentabilidade/icons/Sustentabilidade_1.svg'
import logo from '../images/lwvolt/LWVoltIcon.svg'
import icon1 from '../images/lwvolt/icon1.svg'
import icon2 from '../images/lwvolt/icon2.svg'
import icon3 from '../images/lwvolt/icon3.svg'
import icon4 from '../images/lwvolt/icon4.svg'

const Molde = props => (
  <>
    <div className="col-lg-12 col-6 d-lg-flex d-block">
      <div className="cardLwvolt col-lg-auto col-auto mt-2 mb-lg-4 mb-2">
        <div className="text-lg-center d-flex align-items-center">
          <img src={props.icon} alt="Icone do Card" className="w-100" />
        </div>
      </div>
      <div className="col-lg-12 col-auto mt-lg-2 d-flex align-items-center justify-content-lg-start justify-content-center p-0 m-0">
        <p className="text-lwart-darkblue font-Spinnaker pl-lg-2 pl-lg-4 line-height-1">{props.text}</p>
      </div>
    </div>
  </>
)

const LWvolt = ({ data }) => {
  const image = getImage(data.paralax.childImageSharp.gatsbyImageData)
  const bgImage = convertToBgImage(image)
  const image2 = getImage(data.paralax2.childImageSharp.gatsbyImageData)
  const intl = useIntl()

  const bgImage2 = convertToBgImage(image2)
  return <>
    <Pagina pagina={data.pagina} />
    <FloatSelo />
    <div>
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage"
      >
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-12 text-center">
              <div><img src={logo} alt="Logo da LW Volt" className="iconLWVolt" /></div>
              <p className="text-white">{parse(intl.formatMessage({ id: 'paginas.lw_volt.banner' }))}</p>
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
    <div className="gradient-blue-to-green"></div>
    <div className="container pr-lg-5 pl-lg-5 pt-5 pb-5">
      <div className="row h-100 justify-content-end align-items-center" style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <div className="col-lg-6">
          <div className="col-lg-11 pl-0">
            <h2 className="text-lwart-blue pb-2">{parse(intl.formatMessage({ id: 'paginas.lw_volt.alta_performance.text.title' }))}</h2>
            <p className="line-height-1">{parse(intl.formatMessage({ id: 'paginas.lw_volt.alta_performance.text.p1' }))}</p>
            <p className="line-height-1">{parse(intl.formatMessage({ id: 'paginas.lw_volt.alta_performance.text.p2' }))}</p>
            <p className="line-height-1">{parse(intl.formatMessage({ id: 'paginas.lw_volt.alta_performance.text.p3' }))}</p>
            <a href={data.arquivo && data.arquivo.arquivo.file.url} target="_blank" rel="noreferrer noopener"><button className="btn btn-primary btn-sustentabilidade">{parse(intl.formatMessage({ id: 'paginas.lw_volt.alta_performance.text.link' }))}</button></a>
          </div>
        </div>
        <div className="col-lg-6 pt-lg-0 pt-5">
          <div className="row">
            <Molde icon={icon1} text={parse(intl.formatMessage({ id: 'paginas.lw_volt.alta_performance.molde.oxidacao' }))}/>
            <Molde icon={icon2} text={parse(intl.formatMessage({ id: 'paginas.lw_volt.alta_performance.molde.contaminantes' }))}/>
            <Molde icon={icon3} text={parse(intl.formatMessage({ id: 'paginas.lw_volt.alta_performance.molde.termicas' }))}/>
            <Molde icon={icon4} text={parse(intl.formatMessage({ id: 'paginas.lw_volt.alta_performance.molde.seguranca' }))}/>
          </div>
        </div>
      </div>
    </div>
    <BackgroundImg
      {...bgImage2}
      Tag="div"
      className="paralaxImageLwvolt"
    >
      <div className="bg-color-lwvolt2">
        <div className="container p-0">
          <div className="row w-100 ml-0" style={{ height: '410px' }}>
            <div className="col-lg-6 offset-lg-6">
              <div className="container h-100 d-flex pl-lg-5 align-items-center">
                <div className="row w-100">
                  <div className="col-lg-8 pr-0">
                    <h3 className="text-lwart-green3 mb-3">{parse(intl.formatMessage({ id: 'paginas.lw_volt.tecnologia.title' }))}</h3>
                    <p className="text-white line-height-normal mb-0">{parse(intl.formatMessage({ id: 'paginas.lw_volt.tecnologia.p' }))}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImg>
    <div className="container p-lg-5 pt-5 pb-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-4 mb-lg-0 mb-2">
                <div className="d-flex justify-content-lg-end">
                  <img src={handFlower} className="rounded-circle bg-lwart-green3 handflorLWVOLT" alt="Icone" />
                </div>
              </div>
              <div className="col-lg-10">
                <p className="font-Spinnaker text-lwart-green3 mb-lg-0 mb-3">{parse(intl.formatMessage({ id: 'paginas.lw_volt.sustentabilidade.p1' }))}</p>
                <h3 className="font-Spinnaker text-lwart-blue pb-3">{parse(intl.formatMessage({ id: 'paginas.lw_volt.sustentabilidade.p2' }))}</h3>
                <p>{parse(intl.formatMessage({ id: 'paginas.lw_volt.sustentabilidade.p3' }))}</p>
                {parse(intl.formatMessage({ id: 'paginas.lw_volt.sustentabilidade.p4' }))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default LWvolt

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/lw-volt/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  arquivo: contentfulDocumentos(categoria: {eq: "lwvolt"}) {
    arquivo {
      file {
        url
      }
    }
  }
  paralax: file(relativePath: {eq: "lwvolt/capa-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralax2: file(relativePath: {eq: "lwvolt/bg-meiolwvolt.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
